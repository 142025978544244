import React, { useContext, useEffect, useState } from "react";
import VideoCard from "../../components/videos/VideoCard";
import { VideosContext } from "../../context/VideosContext";
import LatestVideo from "../../components/videos/LatestVideo";
import { AuthContext } from "../../context/AuthContext";
import FilterSection from "../../components/videos/FilterSection";
import { FiltersContext } from "../../context/FiltersContext";
import { Link } from "@reach/router";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import ClassTypeCarousel from "../../components/videos/ClassTypeCarousel";
import moment from "moment";
import { VideoTypeContext } from "../../context/VideoTypeContext";
import Pagination from "../../components/global/Pagination";
import ExpiredAtHome from "../../components/global/ExpiredAtHome";
import { getLastCategoryAccess } from "../../utils";

const Videos = () => {
  const [page, setPage] = useState(1);
  const { user, getUsuario } = useContext(AuthContext);
  const { query, showFavorites, video_type_id, instructor_id } =
    useContext(FiltersContext);

  const {
    videos,
    spinner,
    favoritos,
    getVideos,
    clearVideos,
    purchase_needed,
    agregarFavorito,
    eliminarFavorito,
  } = useContext(VideosContext);

  const { video_types } = useContext(VideoTypeContext);

  const { class_categories, getClassCategories } =
    useContext(ClassCategoryContext);

  useEffect(() => {
    getUsuario();
    getClassCategories();
  }, []);

  useEffect(() => {
    clearVideos();
    getVideos({ video_type_id, instructor_id, page });
  }, [page]);
  const filtersClear = () => {
    return (
      (instructor_id === "" || instructor_id === null) &&
      (video_type_id === "" || video_type_id === null) &&
      (query === "" || query === null)
    );
  };

  const getVideoType = (video_types, video_type_id) => {
    if (
      Array.isArray(video_types) &&
      video_type_id !== "" &&
      video_type_id !== null
    ) {
      return video_types.find(
        (video_type) =>
          parseInt(video_type.video_type_id) === parseInt(video_type_id)
      );
    }
  };

  const getVideosRender = () => {
    if (showFavorites) {
      return favoritos;
    }
    if (Array.isArray(videos)) {
      return videos.filter((video) => video.class_category_id < 6);
    }
    return videos;
  };

  const renderExpiration = (class_category_id) => {
    let category_access = getLastCategoryAccess(user.category_accesses, class_category_id);
    if (!category_access) {
      category_access = getLastCategoryAccess(user.vigencias, class_category_id);
    }
    if (category_access) {
      if (category_access.expiration_date !== null) {
        let expiration_date = moment(category_access.expiration_date);
        if (expiration_date.isBefore(moment())) {
          category_access = getLastCategoryAccess(user.vigencias, class_category_id);
          if (category_access) {
            if(category_access.expiration_date === null) {
              return (
                <span className="badge badge-pill bg-verde text-dark">
                  Acceso Activo
                </span>
              );
            }
            expiration_date = moment(category_access.expiration_date);
            if (expiration_date.isSameOrAfter(moment())) {
              return (
                <span className="badge badge-pill bg-verde text-dark">
                  Acceso Activo
                </span>
              );
            }
          }
          return <span className="badge badge-pill bg-danger">Expirado el {expiration_date.format("DD MMM YYYY")}</span>;
        }
        return <span className="badge badge-pill bg-primary text-dark">Expira en: {expiration_date.format("DD MMM YYYY")}</span>
      }
      return (
        <span className="badge badge-pill bg-verde text-dark">
          Acceso Activo
        </span>
      );
    }
    return <span className="badge badge-pill bg-danger">Sin Acceso</span>;
  };

  const renderAtHomeExpiration = () => {
    const max_expiration_date = renderExpiration(2);
    const tomorrow = moment()
      .startOf("day")
      .add(1, "day")
      .format("DD MMM YYYY");
    const has_shown_modal = window.localStorage.getItem(
      "athome_expiration_date"
    );
    if (
      tomorrow === max_expiration_date &&
      max_expiration_date !== has_shown_modal
    ) {
      return <ExpiredAtHome max_expiration_date={max_expiration_date} />;
    }
  };

  const renderVideos = (videosRender, video_type) => {
    if (!Array.isArray(videosRender)) {
      return <div className="spinner-border"></div>;
    }
    if (videosRender.length === 0) {
      if (showFavorites) {
        return <p>Aún no has marcado ningún video como favorito.</p>;
      }
      if (filtersClear()) {
        return <p>Lo sentimos, no encontramos videos disponibles.</p>;
      }
      if (!video_type) {
        return <p>Lo sentimos, no encontramos videos para tu búsqueda.</p>;
      } else if (video_type.class_category_id < 5) {
        return <p>Lo sentimos, no encontramos videos para tu búsqueda.</p>;
      }
    }
    return videosRender.map((video) => (
      <VideoCard
        user={user}
        video={video}
        key={video.video_id}
        agregarFavorito={agregarFavorito}
        eliminarFavorito={eliminarFavorito}
      />
    ));
  };

  const renderLatest = () => {
    if (!showFavorites && query === "" && !spinner && filtersClear()) {
      if (videos && videos !== null) {
        const video = videos[0];
        if (video) {
          return <LatestVideo video={video} />;
        }
      }
    }
    if (purchase_needed) {
      return (
        <div className="container-fluid px-0">
          <p>Lo sentimos, no cuentas con acceso a Latina At Home. </p>
          <Link className="btn btn-primary" to="/checkout/15">
            Ir a Shop
          </Link>
        </div>
      );
    }
  };

  const renderPages = (videosRender) => {
    if (spinner) {
      return <div className="spinner-border"></div>;
    }
    if (!purchase_needed) {
      if (Array.isArray(videosRender)) {
        if (videosRender.length > 0) {
          return <Pagination page={page} setPage={setPage} />;
        }
      }
    }
  };

  const renderExclusives = (video_type) => {
    console.log({ videos });
    if (Array.isArray(class_categories) && Array.isArray(videos)) {
      let classCategoriesRender = class_categories.filter(
        (class_category) => class_category.class_category_id > 5
      );
      if (video_type && Array.isArray(video_types)) {
        const { class_category_id } = video_type;
        classCategoriesRender = classCategoriesRender.filter(
          (class_category) =>
            class_category_id === class_category.class_category_id
        );
        if (classCategoriesRender.length === 0 && video_type) {
          return <p>No encontramos contenido exclusivo para tu búsqueda.</p>;
        }
      }
      if (classCategoriesRender.length === 0) {
        <p>No encontramos contenido exclusivo disponible.</p>;
      }
      return classCategoriesRender.map((class_category) => (
        <ClassTypeCarousel
          user={user}
          class_category={class_category}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
          key={class_category.class_category_id}
        />
      ));
    }
  };

  const renderMainVideos = (video_type) => {
    if (!purchase_needed) {
      const videosRender = getVideosRender();
      return (
        <div>
          <FilterSection />
          <div className="row">{renderVideos(videosRender, video_type)}</div>
          <div className="container-fluid text-right px-0">
            {renderPages(videosRender)}
          </div>
        </div>
      );
    }
  };

  const renderExclusiveVideos = (video_type) => {
    if (!Array.isArray(favoritos)) {
      return (
        <div>
          <h2 className="display-4 mt-4">
            <span className="text-primary">Exclusive</span> Content
          </h2>
          <div className="row">{renderExclusives(video_type)}</div>
        </div>
      );
    }
  };

  const video_type = getVideoType(video_types, video_type_id);

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-8">
          <h1 className="display-5">Mis Clases Online</h1>
        </div>
        <div className="col-4 text-right">{renderExpiration(2)}</div>
      </div>
      {renderLatest()}
      {renderAtHomeExpiration()}
      {renderMainVideos(video_type)}
      {renderExclusiveVideos(video_type)}
    </div>
  );
};

export default Videos;
